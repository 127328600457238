import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import { useSelector } from 'react-redux';
import MyrchTag from '../../components/MyrchTag/MyrchTag';
import styled, { css } from "styled-components";
import { isDark } from '../../utils/isDark';
import { media } from '../../utils/mediaQuery';
import { createSlug } from '../../utils/makeSlug';


const Wrapper = styled.div` 
  display: flex;
  position: relative;
  margin: 0 0 30px;
  z-index: 1;
  flex-wrap: wrap;

  ${media.xlarge`
    margin: 0 0 40px;
  `}
`

const TagWrapper = styled.div`
  box-sizing: border-box;
  display: block;
  margin: 12px 12px 0 0;
  ${media.large`
    margin: 20px 20px 0 0;
  `}
`
const Tags = (props) => {
  const { context } = props;
  const testEl = useRef();
  const utilRedux = useSelector(state => state.utilReducer);
  const filterRedux = useSelector(state => state.filterReducer);
  const data = useStaticQuery(graphql`
    query filterQuery {
      allPrismicFilter {
        edges {
          node {
            data { 
              tags {
                tag_name
                tag_text_active
                background
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    
  }, [])

  const tags = data.allPrismicFilter.edges[0].node.data.tags;

  return (
    <Wrapper ref={testEl}>
      <TagWrapper><Link style={{textDecoration: 'none'}} to={`/`}><MyrchTag textColour={utilRedux.insta ? '#111' : context && context.text_colour ? context.text_colour : null} dark={utilRedux.insta ? false : context && context.background ? isDark(context.background) : false} activeTextColor={utilRedux.insta ? '#9F2FA0' : context && context.tag_text_active ? context.tag_text_active : '#fff'} active={filterRedux && !filterRedux.selectedTag || filterRedux.selectedTag === '' ? true: false }>All</MyrchTag></Link></TagWrapper>
      { tags && tags.length > 0 ? tags.map((tag) => {
        const slug = createSlug(tag.tag_name);
        return (
          <TagWrapper><Link style={{textDecoration: 'none'}} to={`/tags/${slug}`}><MyrchTag textColour={utilRedux.insta ? '#111' : context && context.text_colour ? context.text_colour : null} dark={utilRedux.insta ? false : context && context.background ? isDark(context.background) : false} activeTextColor={utilRedux.insta ? '#9F2FA0' : context && context.tag_text_active ? context.tag_text_active : null} active={filterRedux && filterRedux.selectedTag === tag.tag_name ? true: false }>{tag.tag_name}</MyrchTag></Link></TagWrapper>
        )
      }) : null }
    </Wrapper>
  )
}

export default Tags;