import React from 'react';
import styled from "styled-components";
import { media } from '../../utils/mediaQuery';
import ShadowContainer from '../../components/ShadowContainer/ShadowContainer';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import MyrchBlock from '../../components/MyrchBlock/MyrchBlock';
import TestImage from '../../components/MyrchBlock/assets/PDP_1.png';
import TestImageHover from '../../components/MyrchBlock/assets/PDP_1_Hover.png';
import TestLogo from '../../components/MyrchBlock/assets/Morgensterns.png';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 20px rgb(17 17 17 / 30%);
  clip-path: inset(-30px 0 0 0);
  z-index: 1;
  //will-change: transform;

  ${media.large`
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
  `}
`

const Inner = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  background: white;

  ${media.large`
    padding: 30px;
  `}
`

const NoBlock = styled.div`
  position: relative;
  font-family: Arial Narrow;
  font-size: 20px;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  
  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.large`
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  `}

  ${media.xxlarge`
    grid-template-columns: repeat(4, 1fr);
  `}
`


const Blocks = (props) => {
  const { blocks } = props;
  return (
    <Wrapper>
        <Inner>
          {blocks && blocks.length > 0 ?
              <Grid>
              { blocks.map((block)=> {
                const data = block.node.data;
                return (
                  <OutboundLink href={data.url.url} target="_blank">
                    <MyrchBlock image={data.image} hover={data['image_hover'].url} logo={data.logo.url} product={data['product_text']} category={data['category_text']} />
                  </OutboundLink>
                )
              }) }
            </Grid>
          : <NoBlock>There is no myrch.</NoBlock>}
        </Inner>
    </Wrapper>
  )
}

export default Blocks;