import React, { useState } from 'react';
import styled from "styled-components";
import { media } from '../../utils/mediaQuery';

import { LazyBlur, LazyBackgroundBlur } from "@manualengineering/react-lazyblur"; 

const Wrapper = styled.div`
display: flex;
box-sizing: border-box;
background: #F9F9F9;
border-radius: 10px;
padding: 15px;
position: relative;
flex-direction: column;
overflow: hidden;

  ${media.large`
  padding: 30px;
  `}
`

const HoverImage = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 1;
opacity: ${props => props.opacity};
transition: opacity .2s ease;
`

const ImageContainer = styled.div`
position: relative;
width: 100%;
margin-bottom: 20px;
`
const Image = styled.img`
width: 100%;
`
const LogoImage = styled.img`
position: relative;
height: 100%;
`

const BottomContainer = styled.div`
position: relative;
width: 100%;
padding-bottom: 10%;
z-index: 2;
`

const BottomInner = styled.div` 
display: flex;
position: absolute;
justify-content: space-between;
height: 100%;
width: 100%;
left: 0;
top: 0;
`


const Shelf = styled.div`
display: flex;
height: 100%;
align-items: flex-end;
`
const LeftShelf = styled(Shelf)`
`
const RightShelf = styled(Shelf)`
font-family: Arial Narrow;
font-size: 14px;
line-height: 20px;
justify-content: flex-end;
`

const ProductText = styled.span`
  color: #888888;
`

const CategoryText = styled.span`
  color: #CFCFCF;
`
const MyrchBlock = (props) => {
  const { image, hover, alt, logo, product, category, href} = props;
  const [ Opacity, setOpacity ] = useState(0);

  return (
    <Wrapper onMouseOver={()=>{ setOpacity(0.5) }} onMouseOut={()=>{ setOpacity(0) }}>
      <HoverImage opacity={Opacity}>
        <LazyBackgroundBlur src={`${hover}&w=1000&q=70`} placeholder={`${hover}&w=100&blur=10&q=50`} />
      </HoverImage>
      <ImageContainer>
        {  image && image.url && image.dimensions ? <LazyBlur
            placeholder={`${image.url}&w=100&blur=10&q=50`} 
            placeholderWidth={image.dimensions.width} 
            placeholderHeight={image.dimensions.height}>
              <Image src={`${image.url}&w=1000&q=85`}/>
        </LazyBlur>  : image ?
          <Image src={image}/>
        : null }
      </ImageContainer>
      <BottomContainer>
        <BottomInner>
          <LeftShelf>
            { logo ? <LogoImage src={logo}/> : null}
          </LeftShelf>
          <RightShelf><ProductText>{ product }</ProductText>{"\u00A0"}<CategoryText>{ category }</CategoryText></RightShelf>
        </BottomInner>
      </BottomContainer>
    </Wrapper>
  );
}

export default MyrchBlock;