import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { media } from '../../utils/mediaQuery';
import { isDark } from '../../utils/isDark';


const GeneralWrapper = styled.button`
  cursor: pointer;
  
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  font-family: Arial Narrow;
  font-size: 22px;
  line-height: 28px;
  border-radius: 10px;
  transition: all .2s ease;
  
  ${media.large`
    font-size: 32px;
    line-height: 40px;
  `}

  ${media.xlarge`
    font-size: 42px;
    line-height: 52px;
  `}
`
const Wrapper = styled(GeneralWrapper)`
  color:${props => props.active && props.activeTextColor ? props.activeTextColor : props.textColour ? props.textColour : props.dark ? '#fff' : '#111'};
  background: ${props => props.active ? props.textColour ? props.textColour : props.dark  ? '#fff' : '#111' : 'transparent'};
  border: 1px solid ${props => props.textColour ? props.textColour : props.dark ? '#fff' : '#111' };

  &:hover {
    ${props => !props.active && css`
      background: ${props => props.textColour ? props.textColour : props.dark  ? '#fff' : '#111'};
      color: ${props => props.activeTextColor ? props.activeTextColor : props.dark  ? '#111' : '#fff'};
    `}
  }
`

const MyrchTag = (props) => {
  const { children, active, dark, textColour, activeTextColor} = props;

  return (
    <Wrapper activeTextColor={activeTextColor} textColour={textColour} active={active} dark={dark}>
      { children }
    </Wrapper>
  )
}

export default MyrchTag;