import React, { useEffect, useState} from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import './fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
`
const Layout = (props) => {
  const { children } = props;

  return (
    <>
      <GlobalStyle />
      { children }
    </>
  )
}

export default Layout;