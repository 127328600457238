export const selectTag = (value) => ({
  type: 'SELECT_TAG',
  payload: value
});

export const setSpacer = (value) => ({
  type: 'SET_SPACER',
  payload: value
});

export const setSpacerInit = (value) => ({
  type: 'SET_SPACER_INIT',
  payload: value
});

export const setInsta = (value) => ({
  type: 'SET_INSTA',
  payload: value
});

export const setGridThresh = (value) => ({
  type: 'SET_GRIDTHRESH',
  payload: value
});

export const triggerToggle = (value) => ({
  type: 'SET_TOGGLE',
  payload: value
});

export const setArrowAngle = (value) => ({
  type: 'SET_ARROW_ANGLE',
  payload: value
});
