import React from 'react';
import styled from "styled-components";
import { media } from '../../utils/mediaQuery';

const Wrapper = styled.div`
  width: 100%;
  min-height: 15px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(17, 17, 17, 0.3);
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  clip-path: inset(-30px 0 0 0);
  z-index: 1;

  ${media.large`
    min-height: 30px;
  `}
`

const Spacer = styled.div`
  background-color: white;
  width: 100%;
  height: 20px;
  z-index: 0;
`
const ShadowContainer = (props) => {
  const { children, className } = props;
  return (
    <div className={className}>
      <Wrapper >
        { children }
      </Wrapper>
    </div>
  )
}

export default ShadowContainer;