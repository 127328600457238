import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from "styled-components";
import { useDispatch, useSelector  } from 'react-redux';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { media } from '../../utils/mediaQuery';
import MyrchLogo from '../../components/MyrchLogo/MyrchLogo';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as ManualLogo } from './assets/Myrch_Icon_ManualEngineering_1.svg';
import { ReactComponent as Instagram } from './assets/instagram.svg';
import InstaBg from './assets/Myrch_Texture_Instagram@2x.jpg';
import Tags from '../Tags/Tags';
import { isDark } from '../../utils/isDark';
import { setSpacer, setSpacerInit, setGridThresh, triggerToggle, setInsta } from '../../actions';

gsap.registerPlugin(ScrollToPlugin); 

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 15px;
  height: 812px;
  color: ${props => props.textColour ? props.textColour : props.dark ? 'white' : '#111' };
  background-color: ${props => props.background || 'white' };
  transition: background-color .2s ease;

  ::after {
    content: '';
    background: url(${InstaBg}) center center no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 20px);
    position: absolute;
    z-index: 0;
    opacity: ${props => props.instagram ? 1 : 0};
    transition: opacity .2s ease;
  }

  ${media.small`
    height: 1024px;
  `}

  ${media.medium`
    height: 600px;
  `}

  ${media.large`
    padding: 30px;
  `}

  @media screen and (min-width: 1200px}) {
    height: 675px;
  }

  ${media.xlarge`
    height: 900px;
  `}

  ${media.xxlarge`
    height: 1080px;
  `}
`
const InnerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const LogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
`

const LogoInner = styled.div`
  width: 100%;
  height: 100%;
`

const VerticalLogo = styled.div`
  width: 100%;
  height: 100%;
  ${media.medium`
    display: none;
  `}
`

const HorizontalLogo = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  ${media.medium`
    display: block;
  `}
`

const CoreElements = styled.div`
  position: relative;
  padding: 0 0 30px;
  z-index: 1;

  ${media.large`
    padding: 0 0 60px;
  `}
`

const Text = css`
  font-family: Arial Narrow;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  padding: 0 0 15px;
  margin: 0;
  z-index: 1;
  position: relative;

  ${media.large`
    font-size: 32px;
    line-height: 40px;
    padding: 0 0 20px;
  `}

  ${media.xlarge`
    font-size: 42px;
    line-height: 52px;
  `}
`
const Title = styled.h1` 
  ${Text}
  display: inline-block;
`
const Description = styled.p` 
  ${Text}
`

const DescriptionInner = styled.span`
  display: block;
`

const FilterName = styled.div`
  ${Text}
  display: inline-flex;
  align-items: center;
  font-style: italic;
`

const CreditLine = styled.div` 
  font-family: Arial Narrow;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  z-index: 1;

  ${media.medium`
    font-size: 20px;
    line-height: 28px;
  `}
`

const Email = styled.span` 
  font-family: Arial Narrow;
  font-style: italic;
  text-decoration: underline;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
`

const ArrowStyled = styled(Arrow)` 
  width: 20px; 
  height: 20px;
  margin: 0 0 0 6px;
  transform: rotate(${props => props.angle ? `${props.angle}deg` : '0deg'});
  transition: rotate .2s ease;
  border: 1px solid ${props => props.textColour ? props.textColour : props.dark ? 'white' : '#111' };
  border-radius: 50%;
  background: ${props => props.background ? props.background : 'white' };
  transition: transform .2s ease;

  path:last-child {
    fill: ${props => props.textColour ? props.textColour : props.dark ? 'white' : '#111' };
  }
  
  ${media.large`
    width: 28px; 
    height: 28px;
    margin: 0 0 0 10px;
  `}

  ${media.xlarge`
    width: 32px; 
    height: 32px;
    border: 2px solid ${props => props.textColour ? props.textColour : props.dark ? 'white' : '#111' };
  `}
`

const ManualStyled = styled(ManualLogo)`
  display: inline-block;
  position: relative;
  transform: translate(0,4px);
  height: 20px;
  path {
    fill: ${props => props.textColour ? props.textColour : props.dark ? 'white' : '#111' };
  }

  ${media.medium`
  transform: translate(0,7px);
    height: 28px;
  `}
`
const InstagramAnchor = styled(OutboundLink)`
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
`
const InstagramStyled = styled(Instagram)`
  width: 20px;
  height: 20px;
  display: block;
  fill: ${props => props.textColour ? props.textColour : props.dark ? 'white' : '#111' };
  

  &:hover {
    fill: #111;
  }

  ${media.large`
    width: 24px;
    height: 24px;
  `}
`

const HomeHead = (props) => {
  const { className, theme } = props;
  const element = useRef();
  const coreElements = useRef();
  const topLineEl = useRef();
  const descEl = useRef();
  const utilRedux = useSelector(state => state.utilReducer);
  const filterRedux = useSelector(state => state.filterReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const handleResize = () => {
      const spacerHeight = coreElements.current.offsetHeight + coreElements.current.getBoundingClientRect().top;
      const elementStyle = window.getComputedStyle(descEl.current);
      const lineHeight = parseInt(elementStyle.getPropertyValue('line-height').replace('px', ''));
      const topLineRect = topLineEl.current.getBoundingClientRect().bottom;
      dispatch(setSpacerInit(spacerHeight - topLineRect - ((lineHeight*2) - (lineHeight/2) + 4)));
      dispatch(setSpacer(spacerHeight));
      dispatch(setGridThresh(topLineRect));
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    

    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(theme);

  const ArrowClicked = () => {
    if(utilRedux.toggle) {
      gsap.to(window,{ duration: .2, scrollTo: utilRedux.spacerHeight - utilRedux.gridThresh});
    } else {
      gsap.to(window,{ duration: .2, scrollTo: 0});
    }
    
  }

  const onInstaEnter = () => {
    dispatch(setInsta(true));
  }

  const onInstaExit = () => {
    dispatch(setInsta(false));
  }

  return (
    <>
    <Wrapper instagram={utilRedux.insta} ref={element} className={className} textColour={utilRedux.insta ? '#111' : theme && theme.text_colour ? theme.text_colour : null} dark={utilRedux.insta ? false : theme && theme.background ? isDark(theme.background) : false} background={theme && theme.background ? theme.background : null }>
      <InnerWrapper>
        <InstagramAnchor href="https://www.instagram.com/myrch.club/" target="_blank" onMouseEnter={onInstaEnter} onMouseLeave={onInstaExit}><InstagramStyled textColour={theme && theme.text_colour ? theme.text_colour : null} dark={theme && theme.background ? isDark(theme.background) : false}/></InstagramAnchor>
        <LogoWrapper>
          <LogoInner><VerticalLogo><MyrchLogo fill={utilRedux.insta ? '#111' : theme && theme.logo ? theme.logo : null } rotated/></VerticalLogo><HorizontalLogo><MyrchLogo fill={utilRedux.insta ? '#111' : theme && theme.logo ? theme.logo : null } /></HorizontalLogo></LogoInner>
        </LogoWrapper>
        <CoreElements ref={coreElements}>
          <div ref={topLineEl}>
            <Title>Myrch(dot)Club®</Title>
            <FilterName>, {filterRedux && filterRedux.selectedTag ? filterRedux.selectedTag : 'All'} 
            <ArrowStyled 
              angle={utilRedux.arrowAngle}
              background={utilRedux.insta ? '#5546C3' : theme && theme.background ? theme.background : false} 
              textColour={utilRedux.insta ? '#111' : theme && theme.text_colour ? theme.text_colour : null}
              dark={utilRedux.insta ? false : theme && theme.background ? isDark(theme.background) : false} 
              up={utilRedux.toggle} 
              onClick={ArrowClicked} />
            </FilterName>
          </div>
          <Description>
            <DescriptionInner ref={descEl}>
              Gathering, documenting, and preserving branded merch into a permanent archive of objects collected from individuals, communities, and institutions.
            </DescriptionInner>
          </Description>
          <Tags context={theme}/>
          <CreditLine>Myrch.Club is built and curated by <ManualStyled textColour={utilRedux.insta ? '#111' : theme && theme.text_colour ? theme.text_colour : null} dark={utilRedux.insta ? false : theme && theme.background ? isDark(theme.background) : false}/></CreditLine>
        </CoreElements>
      </InnerWrapper>
    </Wrapper>
    </>
  )
}

export default HomeHead;