import React from 'react';
import styled from "styled-components";
import { ReactComponent as Logo } from './assets/Myrch.Club_Script.svg';
import { ReactComponent as RotatedLogo } from './assets/Myrch.Club_Script-rotated.svg';

const RotatedLogoStyled = styled(RotatedLogo)` 
width: 100%;
height: 100%;
fill: ${props => props.fill ? props.fill : '#FF0000'};
transition: fill .2s ease;
`

const LogoStyled = styled(Logo)` 
width: 100%;
height: 100%;
fill: ${props => props.fill ? props.fill : '#FF0000'};
transition: fill .2s ease;
`

const MyrchLogo = (props) => {
  const { rotated, fill } = props;
  
  return (
      <>
        { rotated ? <RotatedLogoStyled fill={fill}/> : <LogoStyled fill={fill}/>}
      </>
  )
}

export default MyrchLogo;